<template>
  <div class="branch-form">
    <v-form class="form" ref="form">
      <v-row>
        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{ $t("overtime_money.qty") }}</label>
          <v-text-field outlined dense v-model="qty"></v-text-field>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{ $t("overtime_money.rate") }}</label>
          <v-text-field outlined dense v-model="rate"></v-text-field>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{ $t("overtime_money.type") }}</label>

          <v-select :items="listType" dense outlined v-model="type"></v-select>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <!-- <label class="label-input">{{
            $t("overtime_money.calculate")
          }}</label>

          <v-select
            :items="listCalculate"
            dense
            outlined
            v-model="calculate"
          ></v-select> -->
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="8">
          <v-btn class="btn-save-change" @click="saveChange()">
            {{ $t("overtime_money.edit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      listType: ["cash", "holiday"],
      listCalculate: ["one", "all"],
      qty: "",
      rate: "",
      type: "",
      calculate: "",
      setting_ot_id: "",
      server_errors: {
        qty: "",
        rate: "",
        type: "",
        calculate: "",
      },
    };
  },
  methods: {
    fetchSettingOtItem() {
      this.$axios
        .get(`company/ot/setting/${this.setting_ot_id}`)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res);
            this.qty = res.data.data.qty;
            this.rate = res.data.data.rate;
            this.type = res.data.data.type;
            this.calculate = res.data.data.calculate;
          }
        });
    },
    saveChange() {
      const items = {
        qty: this.qty,
        rate: this.rate,
        type: this.type,
        calculate: this.calculate,
      };
      this.$axios
        .put(`company/ot/setting/${this.setting_ot_id}`, items)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.$router
              .push({
                name: "setting_ot.index",
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },

  created() {
    this.setting_ot_id = this.$route.params.setting_ot_id;
    this.fetchSettingOtItem();
  },
};
</script>

<style lang="scss" scoped>
.branch-form {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 800px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
